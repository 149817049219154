<template>
  <div class="Nsfw">
    <transition name="fade" mode="out-in">
      <alert v-if="alertOpen" :content="alertContent" @confirm="closeAlert" />
    </transition>
    <div class="panel">
      <div class="Grid-row">
        <div class="header-title">Unsafe content</div>
      </div>
    </div>
    <div class="Grid-row">
      <div class="Grid-column-12">
        <ul class="Nsfw__list">
          <li class="Nsfw__list-row expiration__list-row--header">
            <span class="Nsfw__list-item">Web url</span>
            <span class="Nsfw__list-item">Author</span>
            <span class="Nsfw__list-item">Ban user</span>
            <span class="Nsfw__list-item">Community id</span>
            <span class="Nsfw__list-item">Ban community</span>
            <span class="Nsfw__list-item">Delete post</span>
            <span class="Nsfw__list-item">Ignore</span>
          </li>
          <li
            v-for="(entry, index) in entries"
            :key="index"
            class="Nsfw__list-row"
          >
            <span class="Nsfw__list-item"
              ><a :href="entry.web_url">Web url</a></span
            >
            <span class="Nsfw__list-item">{{ entry.username }}</span>
            <span class="Nsfw__list-item">
              <button
                class="button button-success"
                @click="banUser(entry.user_id)"
              >
                Ban user
              </button>
            </span>
            <span class="Nsfw__list-item">{{ entry.space_id || 'None' }}</span>
            <span class="Nsfw__list-item">
              <button
                v-if="entry.space_id"
                class="button button-success"
                @click="banCommunity(entry.space_id)"
              >
                Ban community
              </button>
            </span>
            <span class="Nsfw__list-item">
              <button
                class="button button-success"
                @click="deletePost(entry.post_id)"
              >
                Delete post
              </button>
            </span>
            <span class="Nsfw__list-item">
              <button
                class="button button-success"
                @click="ignoreEntry(entry.entry_id)"
              >
                Ignore
              </button>
            </span>
          </li>
          <infinite-loading
            @if="entriesNotLoaded"
            @infinite="infiniteHandler"
          ></infinite-loading>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/common/navigation/Alert';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Nsfw',
  components: {
    Alert,
    InfiniteLoading,
  },
  data() {
    return {
      error: false,
      alertOpen: false,
      alertContent: null,
    };
  },
  computed: {
    entries: {
      get() {
        return this.$store.getters.entries;
      },
    },
    entriesNotLoaded: {
      get() {
        return this.$store.getters.entriesNotLoaded;
      },
    },
  },
  methods: {
    getNsfwPosts() {
      this.$store
        .dispatch('getNsfwPosts')
        .then(() => {})
        .catch(() => {});
    },
    banUser(userId) {
      this.$store
        .dispatch('banPostAuthor', { userId })
        .then(() => {
          this.alertContent = 'User banned';
          this.alertOpen = true;
        })
        .catch(() => {
          this.alertContent =
            'There were erros processing request. Please try again.';
          this.alertOpen = true;
        });
    },
    deletePost(postId) {
      this.$store
        .dispatch('deletePost', { postId })
        .then(() => {
          this.alertContent = 'Post deleted';
          this.alertOpen = true;
        })
        .catch(() => {
          this.alertContent =
            'There were erros processing request. Please try again.';
          this.alertOpen = true;
        });
    },
    banCommunity(communityId) {
      this.$store
        .dispatch('banPostCommunity', { communityId })
        .then(() => {
          this.alertContent = 'Community banned';
          this.alertOpen = true;
        })
        .catch(() => {
          this.alertContent =
            'There were erros processing request. Please try again.';
          this.alertOpen = true;
        });
    },
    ignoreEntry(entryId) {
      this.$store
        .dispatch('ignoreEntry', { entryId })
        .then(() => {
          this.alertContent = 'Post ignored';
          this.alertOpen = true;
        })
        .catch(() => {
          this.alertContent =
            'There were erros processing request. Please try again.';
          this.alertOpen = true;
        });
    },
    infiniteHandler(state) {
      this.$store.dispatch('getNsfwPosts').then(() => {
        if (this.entriesNotLoaded) {
          state.loaded();
        } else {
          state.complete();
        }
      });
    },
    closeAlert() {
      this.alertOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/nsfw/Nsfw.scss';
</style>
